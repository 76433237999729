import { React } from "react";
import "./Tuition.css";

function Tuition() {
  return (
    <div class="tuition-container">
      <p>HELLO AILYN Tuition</p>
    </div>
  );
}

export default Tuition;
